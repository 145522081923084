const oTooltip = require('@financial-times/o-tooltip').default
import { shouldSeeShareTooltip, shouldSeeAnnotationsTooltip } from './enterprise-view-links-tooltip'
import { sessionId } from 'next-session-client'
import * as nTracking from '@financial-times/n-tracking'

const initViewLinksTooltip = async () => {
	const ftSession = sessionId()
	const showTooltip = await shouldSeeShareTooltip(ftSession)

	if (showTooltip) {
		nTracking.broadcast('oTracking.event', {
			category: 'component',
			action: 'init',
			messaging: 'enterprise-share-view-links-tooltip',
		})

		const tooltipElement = document.querySelector('[data-trackable=my-ft]')

		const opts = {
			target: 'o-header-top-link-myft',
			content:
				'<b>Check your shared links</b><br>See which articles you’ve<br> shared and how many<br> views you have left for<br> each one.',
			position: 'below',
			showOnConstruction: true,
		}

		const tooltip = new oTooltip(tooltipElement, opts)

		//Appending close button because it is missing after tooltip initialization
		const button = document.createElement('button')
		button.className = 'o-tooltip-close'
		button.setAttribute('aria-label', 'Close tooltip')
		button.setAttribute('title', 'Close tooltip')
		tooltip.tooltipEl.appendChild(button)
	}
}

const initViewAnnotationsTooltip = async () => {
	const ftSession = sessionId()
	const showTooltip = await shouldSeeAnnotationsTooltip(ftSession)
	if (showTooltip) {
		nTracking.broadcast('oTracking.event', {
			category: 'component',
			action: 'init',
			messaging: 'enterprise-annotation-view-links-tooltip',
		})

		const tooltipElement = document.querySelector('[data-trackable=my-ft]')

		const opts = {
			target: 'o-header-top-link-myft',
			content: '<b>Find your highlights</b><br>See all your saved<br> highlights in one place.',
			position: 'below',
			showOnConstruction: true,
		}

		const tooltip = new oTooltip(tooltipElement, opts)

		//Appending close button because it is missing after tooltip initialization
		const button = document.createElement('button')
		button.className = 'o-tooltip-close'
		button.setAttribute('aria-label', 'Close tooltip')
		button.setAttribute('title', 'Close tooltip')
		tooltip.tooltipEl.appendChild(button)
	}
}

export { initViewLinksTooltip, initViewAnnotationsTooltip }
