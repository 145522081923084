const VIEW_LINKS_TOOLTIP_STORAGE_KEY = 'enterprise-view-links-tooltip'
const ES_HOST_ADDRESS = 'https://enterprise-sharing-api.ft.com'

const VIEW_ANNOTATIONS_TOOLTIP_STORAGE_KEY = 'enterprise-view-annotations-tooltip'
const UA_HOST_ADDRESS = 'https://enterprise-user-annotations-api.ft.com'

const hasCreatedAShare = async (sessionId) => {
	const options = {
		method: 'get',
		headers: {
			Authorization: `Bearer ${sessionId}`,
		},
	}

	const numberOfShares = await fetch(`${ES_HOST_ADDRESS}/v1/shares/me/1`, options)
		.then((response) => {
			if (response.ok) {
				return response.json()
			}
			console.error('There was an error fetching the shares data.')
			return []
		})
		.catch((e) => {
			console.error(e)
		})

	return numberOfShares.length >= 1
}

const hasCreatedAnnotation = async (sessionId) => {
	const options: RequestInit = {
		method: 'get',
		credentials: 'include',
	}
	const annotations = await fetch(`${UA_HOST_ADDRESS}/v1/user-annotations/me`, options)
		.then((response) => {
			if (response.ok) {
				return response.json()
			}
			console.error(response)
			return []
		})
		.catch((error) => {
			console.error(error)
		})
	return annotations.length >= 1
}

const shouldSeeAnnotationsTooltip = async (sessionId) => {
	return await shouldSeeTooltip(sessionId, VIEW_ANNOTATIONS_TOOLTIP_STORAGE_KEY, hasCreatedAnnotation)
}

const shouldSeeShareTooltip = async (sessionId) => {
	return await shouldSeeTooltip(sessionId, VIEW_LINKS_TOOLTIP_STORAGE_KEY, hasCreatedAShare)
}

const shouldSeeTooltip = async (sessionId, tooltipKey, userAwarenessFunc) => {
	const tooltipLocalStorage = JSON.parse(window.localStorage.getItem(tooltipKey))
	const today = new Date()
	const todayString = today.toLocaleString('en-GB', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	})

	if (!tooltipLocalStorage) {
		const hasShared = await userAwarenessFunc(sessionId)

		if (!hasShared) {
			return false
		}

		const expireDate = new Date()
		expireDate.setDate(expireDate.getDate() + 3)

		window.localStorage.setItem(
			tooltipKey,
			JSON.stringify({
				seen: false,
				expireDate: expireDate.toLocaleString('en-GB', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				}),
			}),
		)

		return true
	}

	if (tooltipLocalStorage && tooltipLocalStorage.seen === false && tooltipLocalStorage.expireDate === todayString) {
		tooltipLocalStorage.seen = true
		window.localStorage.setItem(tooltipKey, JSON.stringify(tooltipLocalStorage))
		return true
	}

	return false
}

export { hasCreatedAShare, hasCreatedAnnotation, shouldSeeShareTooltip, shouldSeeAnnotationsTooltip }
